import { render, staticRenderFns } from "./PatrolPointDataChart.vue?vue&type=template&id=2fb60096&scoped=true"
import script from "./PatrolPointDataChart.vue?vue&type=script&lang=js"
export * from "./PatrolPointDataChart.vue?vue&type=script&lang=js"
import style0 from "./PatrolPointDataChart.vue?vue&type=style&index=0&id=2fb60096&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fb60096",
  null
  
)

export default component.exports